<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12">
      <h3>Docentes</h3>
    </v-col>
    
    <v-col cols="3" class="">
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Buscar docente"
        outlined
        dense
      ></v-text-field>
    </v-col>

    <v-col cols="12" class="">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="items.length"
        :search="search"
        :loading="loading"
        hide-default-footer
        no-data-text="Ainda foram encontrados docentes"
        loading-text="Buscando docentes... pode demorar alguns segundos"
      >
        <template v-slot:item="props">
          <tr @click="showProfessor(props.item)">
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.department }}</td>
            <td class="text-xs-left">{{ props.item.guidances }}</td>
            <td class="text-xs-left">{{ props.item.coGuidances }}</td>
            <td class="text-xs-left">{{ props.item.total }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Professors",
  props: [],
  components: {},
  data() {
    return {
      items: [],
      loading: false,
      search: "",
      headers: [
        {
          sortable: true,
          text: "Matrícula",
          value: "academicCode",
          align: "left",
        },
        { sortable: true, text: "Nome", value: "name", align: "left" },
        {
          sortable: true,
          text: "Departamento",
          value: "department",
          align: "left",
        },
        {
          sortable: true,
          text: "Orientandos",
          value: "guidances",
          align: "left",
        },
        {
          sortable: true,
          text: "Co-orientandos",
          value: "coGuidances",
          align: "left",
        },
        {
          sortable: true,
          text: "Total",
          value: "total",
          align: "left",
        },
      ],
    };
  },

  created() {
    this.getAsyncProfessors();
  },

  computed: {
    ...mapState("professors", ["professors"]),
  },

  methods: {
    ...mapActions("professors", ["ActionGetProfessors"]),

    async getAsyncProfessors() {
      try {
        this.loading = true;
        await this.ActionGetProfessors();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    showProfessor(professor) {
      this.$router.push({
        name: "Professor",
        params: { academic_code: professor.academicCode, professor: professor },
      });
    },
  },

  watch: {
    professors(newValue) {
      newValue.map((docente) => {
        this.items.push({
          academicCode: docente.academicCode,
          name: docente.name,
          department: docente.department,
          guidances: docente.guidances,
          coGuidances: docente.coGuidances,
          total: docente.guidances + docente.coGuidances,
        });
      });
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>